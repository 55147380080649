<template>
  <div>
    <b-collapse id="links">
      <b-card>
        <a href="https://docs.google.com/spreadsheets/d/1Gdxge5z8JpkiKTlJAVLP4t2hvHWpHpFmN5GVcK0VkL4" target="_blank">스마트스토어 Sheet</a><br/>
        <a href="https://sell.smartstore.naver.com/#/home/dashboard" target="_blank">스마트스토어센터</a><br/>
        <a href="https://partner.lotteimall.com/main/Login.lotte" target="_blank">lotteimall partner</a><br/>
        <a href="http://proxy-hub.balaan.io:53535/proxy/alive?type=list" target="_blank">Proxy 보기</a><br/>
        <a href="https://cloud.mongodb.com/freemonitoring/cluster/SJJUV2JL7D2X2LW242CCLKIWMYDIC3DV" target="_blank">몽고DB 모니터링</a><br/>
        <a href="https://github.com/search?l=&q=user%3Abalaan-team1+" target="_blank">깃헙 팀 검색</a><br/>
        <a href="https://github.com/balaan-team1/hub/compare/master@%7B2%20day%7D...master@%7B0%20day%7D" target="_blank">깃헙 commit 사이 비교</a><br/>
        <a href="https://join.shopping.naver.com/faq/list.nhn?catgCd=H00015" target="_blank">네이버 쇼핑상품 검색 알고리즘</a><br/>
        <a href="http://stat.balaan.io:3000/" target="_blank">메타베이스(kr 대시보드)</a><br/>
      </b-card>
    </b-collapse>
    <b-card v-if="R('DEV')">
      <b-button class="mb-3 mr-2" variant="secondary" v-b-toggle.links>(구) Links</b-button>
      <b-button class="mb-3" variant="primary" @click="devRandomLunch">DevRandomLunch</b-button>
      <div class="mb-1">
        <b-button class="mr-2" variant="success" @click="pull('noNpm=1')">Client,Server</b-button>
        <b-button class="mr-2" variant="success" @click="pull('clientOnly=1')">Client</b-button>
        <b-button class="mr-2" variant="success" @click="pull('serverOnly=1')">Server</b-button>
        <b-button class="mr-2" variant="success" @click="pull('batchOnly=1')">HubBatchRestart</b-button>
        <b-button class="mr-2" variant="success" @click="pull()">Client,Server with npm</b-button>
        <b-button class="mr-2" variant="success" @click="pull('serverNpm=1')">Server with npm</b-button>
      </div>
      <b-button class="mb-1 mr-2" variant="primary" @click="TestByURI">TestByURI</b-button>
      <span>{{JSON.stringify(result.test)}}</span><br/>

      <b-button class="mb-1 mr-2" variant="success" @click="WorkByURI">WorkByURI</b-button>
      <span>{{JSON.stringify(result.work)}}</span><br/>

      <b-button class="mb-1 mr-2" variant="dark" @click="DataMigrationByURI">DataMigrationByURI</b-button>
      <b-button class="mb-1 mr-2" variant="warning" @click="collCopy">collCopy</b-button>
      <b-button class="mb-1 mr-2" variant="warning" @click="copyTable">copyTable</b-button>
      <span>{{JSON.stringify(result.data)}}</span><br/>

      <b-button class="mb-1 mr-2" variant="warning" @click="getJsonURI">getJsonURI</b-button>
      <span>{{JSON.stringify(result.get)}}</span><br/>

      <b-button class="mb-1 mr-2" variant="info" @click="getLogResReqURI">getLogResReqURI</b-button>
      <b-button class="mb-1 mr-2" variant="info" @click="setLogResReqURI">setLogResReqURI</b-button>
      <b-button class="mb-1 mr-2" variant="warning" @click="removeLogResReqURIAll">removeLogResReqURIAll</b-button>
      <b-button class="mb-1 mr-2" variant="warning" @click="getMinusDiscount">getMinusDiscount</b-button>
      <span>{{JSON.stringify(result.uri, null, 2)}}</span><br/>

      <b-button class="mb-1 mr-2" variant="info" @click="updateGlobalMeta">updateGlobalMeta</b-button>
      <span>{{JSON.stringify(result.meta)}}</span><br/>

      <!--
      option group test
      -->
      {{JSON.stringify(values)}}<br/>
      <b-form-radio-group v-model="values.a" :options="options.list"></b-form-radio-group>
      <!-- checkbox 는 초기화 객체가 '' 이냐 [] 이냐에 따라 multiselect 가불가가 결정된다. 그러나 '' 일 경우 콘솔에 warn 이 뜬다. -->
      <b-form-checkbox-group v-model="values.b" :options="options.list"></b-form-checkbox-group>
<!--      <b-form-checkbox-group id="mall" name="selectedMall" v-model="values.c">
        <b-form-checkbox v-for="s in options.list" :key="s.value" :value="s.value">{{ s.label }}</b-form-checkbox>
      </b-form-checkbox-group>-->
      <b-form-select v-model="values.d" index="value" :options="options.list"></b-form-select>
      <v-select v-model="values.e" multiple label="label" index="value" :loading="false" :options="options.list" placeholder="multiple"></v-select>
      <v-select v-model="values.f" label="key" :options="options.list" placeholder=""></v-select>
      <v-multiselect class="mb-2" v-model="values.g" :options="options.list"
                     label="label" value="value" :show-labels="true"
                     placeholder="선택해주세요."></v-multiselect>

      <br/>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse1>ETC</b-button>
      <b-collapse id="collapse1">
        <b-button class="mr-2" variant="success" @click="toast">Toast</b-button>
        <br/>
        <b-button class="mr-2" variant="danger" @click="mergePriceTable">mergePriceTable</b-button>
        <b-button class="mr-2" variant="danger" @click="mergePriceTable('all')">mergePriceTableAll</b-button>
        <b-button class="mr-2" variant="danger" @click="promptMergePriceTable">mergePriceTableOneOrder</b-button>
        <b-button class="mr-2" variant="danger" @click="setStrNum">setStrNum</b-button>
        <br/>
        <b-button class="mr-2" variant="info" @click="callFeedRenewCount">callFeedRenewCount</b-button>
        <b-button class="mr-2" variant="info" @click="callFeedRenewPrice">callFeedRenewPrice</b-button>
        <br/>
        <b-button class="mr-2" variant="info" @click="syncStockMulti">sync stock multi</b-button>
        <br/>
        <b-button class="mr-2" variant="info" @click="get('/order/realpacking')">realpacking</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/setAbroadDelComplete')">setAbroadDelComplete</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/setDelComplete')">setDelComplete</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/setFasstoDelDomestic')">setFasstoDelDomestic</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/setPccc')">setPccc</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/setOrderInfo')">setOrderInfo</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/importGodo')">importGodo</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/syncGodo')">syncGodo</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/importSSOrder')">importSSOrder</b-button>
        <b-button class="mr-2" variant="info" @click="get('/order/markDelay')">markDelay</b-button>
        <br/>
        <b-button class="mr-2" variant="info" @click="get('/test/euckr')">euckr</b-button>
        <b-button class="mr-2" variant="info" @click="get('/test/appendPriceTable')">appendPriceTable</b-button>
        <b-button class="mr-2" variant="info" @click="get('/test/statOrder')">statOrder</b-button>
        <b-button class="mr-2" variant="info" @click="get('/test/test1')">test1</b-button>
        <br/>
<!--        <b-button class="mr-2" variant="info" @click="get('/ss/product/registDaily')">ss registDaily</b-button>
        <b-button class="mr-2" variant="info" @click="get('/ss/syncAll')">ss syncAll</b-button>
        <b-button class="mr-2" variant="info" @click="get('/ss/removeDup')">ss removeDup</b-button>
        <b-button class="mr-2" variant="info" @click="get('/ss/renewGoodsHasRealpacking')">ss renewGoodsHasRealpacking</b-button>
        <b-button class="mr-2" variant="info" @click="get('/ss/test/syncProductTest')">syncProductTest</b-button>
        <b-button class="mr-2" variant="info" @click="ssSyncProd">ssSync product with st</b-button>
        <b-button class="mr-2" variant="info" @click="ssDirect">ssDirect</b-button>
        <b-button class="mr-2" variant="info" @click="syncProductTest">syncProductTest</b-button>-->
        <b-button @click="()=>{$S.tt = ($S.tt || 0) + 1}">inc {{$S.tt}}</b-button>
        <b-input v-model="$S.tt"></b-input>
        <br/>
        <b-button href="http://proxy-hub.balaan.io:53535/proxy/alive?type=list" target="_blank">Proxy 보기</b-button>
        <b-row>
          <b-col cols="6">
            Coll: <b-input v-model="xlsxColl"></b-input>
            Id: <b-input v-model="xlsxId"></b-input>
            <div id="dropNew" class="drop" data-type="new" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxNew.value = null;$refs.xlsxNew.click()}">
              New File Drop or Click
            </div>
            <input type="file" ref="xlsxNew" data-type="new" style="display: none" @change="handleFile">
          </b-col>
        </b-row>
        <date-input class="form-inline" v-model="date"></date-input>
        <date-from-to :from.sync="dateFrom" :to.sync="dateTo" :init="'10 day'"></date-from-to>
        <b-button @click="$utils.alert(JSON.stringify({date, dateFrom, dateTo}))">show date</b-button>
      </b-collapse>
      <b-button class="m-1" variant="success" @click="openToast">Toast Test</b-button>

    </b-card>

    <htb v-model="htbItems" :config="htbConfig"></htb>
  </div>
</template>

<script>
import {postFeed, getJson, postJson, getMeta, getPubMeta, SellerId, postSS} from '@/shared/api'
import * as utils from '@/shared/utils'
import store from '@/store';
const {state:S, getters:{R}} = store;
import io from 'socket.io-client';
import {down, getHeaderRow, fixData, readXlsx} from '@/shared/impexp'
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'Settings',
  title: 'Settings',
  components: {htb},
  // beforeCreate() { console.log('beforeCreate'); },
  data() {
    return {
      R,
      result: {test:'', work:'', data:'', get:'', uri:'', meta:''},
      xlsxColl: '',
      xlsxId: '',
      date: null,
      dateFrom: '',
      dateTo: '',
      values: {
        a: [], b: [], c: '', d: '',
        e: [], f: null, g: null
      },
      options: {
        list: [
          {text: 'text A', value: 'value A', label: 'label A', key: 'key A'},
          {text: 'text B', value: 'value B', label: 'label B', key: 'key B'}
        ]
      },
      htbItems: [],
      htbConfig: {
        settings: {
          contextMenu: true,
          filters: true,
          // dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
          dropdownMenu: ['filter_by_condition', 'filter_action_bar'],
        }
      }
    }
  },
  // created() {},
  // beforeMount() { console.log('beforeMount'); },
  async mounted() {
    console.log(this.IS_DEV);

    window.$vue = this;
    this.$socket.on('progress', (pid, type, desc, per) => {
      if (per != undefined) {
        console.log('progress', pid, type, desc, per);
      } else {
        console.log('progress', pid, type, desc);
      }
    });
    // this.$socket.on('stat', (...arr) => {
    //   console.log(...arr);
    // });
    // this.$socket.emit('join', '910872e7-2867-48cb-879f-5cc83257c231');
    this.$socket.on('hostname', (a)=>console.log('hostname', a));
    let hash = await getPubMeta('shop,brand');
    console.log(hash)
    console.log(await getMeta('shop,brand'))

    this.getGMList();
  },
  // beforeUpdate() { console.log('beforeUpdate'); },
  // updated() { console.log('updated'); },
  beforeDestroy() {
    this.$socket.off('progress');
    this.$socket.off('hostname');
    // this.$socket.off('stat');
  },
  sockets: {
    soctest(q) {
      console.log('sockets test', q);
    }
  },
  // destroyed() { console.log('destroyed'); },
  // activated() { console.log('activated'); },
  // deactivated() { console.log('deactivated'); },
  methods: {
    async devRandomLunch() {
      const nameStr = prompt('이름을 콤마(,) 로 구분해서 넣어주세요.', localStorage.getItem('devRandomLunch') || '송수현,변현창,양세찬,최경우,원예린,권하경,김헌유,유지윤,박보미,경명호');
      if (!nameStr) return;
      await postJson('/work/devRandomLunch', {nameStr});
      localStorage.setItem('devRandomLunch', nameStr);
    },
    async pull(query) {
      let j = await getJson('/pull' + (query ? '?' + query : ''));
      if (j) {
        if (j.is_pc || j.is_dev) {
          alert('서버에서 적용해주세요');
        }
      }
    },
    async get(url) {
      let j = await getJson(url);
      if (j) alert(JSON.stringify(j));
    },
    async TestByURI() {
      let uri = prompt('/test/ 이후 경로를 적어주세요', localStorage.getItem('testuri') || '');
      if (!uri) return;
      this.result.test = '...';
      localStorage.setItem('testuri', uri);
      this.result.test = await getJson('/test/' + uri);
    },
    async WorkByURI() {
      let uri = prompt('/work/ 이후 경로를 적어주세요', localStorage.getItem('workuri') || '');
      if (!uri) return;
      this.result.work = '...';
      localStorage.setItem('workuri', uri);
      this.result.work = await getJson('/work/' + uri);
    },
    async DataMigrationByURI() {
      let uri = prompt('/data/migration?f= 이후 경로를 적어주세요', localStorage.getItem('datauri') || '');
      if (!uri) return;
      this.result.data = '...';
      localStorage.setItem('datauri', uri);
      this.result.data = await getJson('/data/migration?f=' + uri);
    },
    async collCopy() {
      let coll = prompt('Prod -> Dev 로 copy 할 collection 을 입력해주세요(ex: db.hub.meta_shops)', localStorage.getItem('collcopy') || '');
      if (!coll) return;
      this.result.data = '...';
      localStorage.setItem('collcopy', coll);
      this.result.data = await getJson('/work/collCopy?backup=false&source=' + coll);
    },
    async copyTable() {
      let table = prompt('Prod -> Dev 로 copy 할 table 을 입력해주세요(ex: stat.team)', localStorage.getItem('copyTable') || '');
      if (!table) return;
      this.result.data = '...';
      localStorage.setItem('copyTable', table);
      this.result.data = await getJson('/work/copyTable?backup=false&src=' + table);
    },
    async getJsonURI() {
      let uri = prompt('/ 로 시작하는 전체 경로를 적어주세요', localStorage.getItem('getjsonuri') || '');
      if (!uri) return;
      this.result.get = '...';
      localStorage.setItem('getjsonuri', uri);
      this.result.get = await getJson(uri);
    },
    async getLogResReqURI() {
      this.result.uri = '...';
      this.result.uri = await getJson('/logReqRes?type=getAll');
    },
    async setLogResReqURI() {
      let uri = prompt('/ 로 시작하는 전체 경로를 적어주세요', localStorage.getItem('setlogresrequri'));
      if (!uri) return;
      this.result.uri = '...';
      localStorage.setItem('setlogresrequri', uri);
      this.result.uri = await getJson(`/logReqRes?uri=${uri}`);
    },
    async removeLogResReqURIAll() {
      this.result.uri = '...';
      this.result.uri = await getJson('/logReqRes?type=removeAll');
    },
    async getMinusDiscount() {
      this.result.uri = '...';
      const j = await getJson('/work/getMinusDiscount');

      if (j) {
        let headers = 'shop,shop_id,goods_id,sku,ID,BrandReferencePrice,BrandReferencePriceExVAT,NetPrice,Discount'.split(',');
        let fields = 'shop,shop_id,goods_id,sku,ID,BrandReferencePrice,BrandReferencePriceExVAT,NetPrice,Discount'.split(',');
        down(j.retailers, headers, fields, `ATELIER_MinusDiscount`, 'csv');
      }
    },
    async updateGlobalMeta() {
      this.result.meta = '...';
      await getJson('/meta/updateGlobalMeta');
      const j = await getJson('/meta/hash?path=global');
      this.result.meta = j;
    },
    toast() {
      this.$bvToast.toast(`복사되었습니다`, {autoHideDelay: 1500});
      // this.$bvToast.toast(`여기를 누르시면 새로고침을 합니다.`, {
      //   title: 'HUB의 새로운 버전이 배포되었습니다',
      //   href: 'javascript:location.reload()',
      //   autoHideDelay: 4000
      // })
    },
    mergePriceTable(type) {
      getJson('/order/mergePriceTable' + (type === 'all' ? '?all=1' : ''));
    },
    async syncStockMulti() {
      let p = prompt('goods no 를 콤마(,)로 이어서 넣어주세요');
      if (!p) return;
      let goods_nos = p.split(',').map(e=>+e);
      let j = await postJson('/goods/syncStockMulti', {goods_nos, renew: true});
      console.log(j);
    },
    async promptMergePriceTable() {
      let orderno = prompt('orderno 를 입력해주세요');
      if (!orderno) return;
      let j = await getJson('/order/mergePriceTable?orderno=' + orderno);
      if (j) alert('완료');
    },
    // async ssSyncProd() {
    //   let st = prompt('시작일자를 YYYYMMDD로 입력해주세요', '20191106');
    //   if (!st) return;
    //   let j = await getJson('/ss/sync?op=product&st='+st);
    // },
    // async syncProductTest() {
    //   let id = prompt('상품번호를 입력해주세요');
    //   if (!id) return;
    //   let j = await getJson('/ss/test/syncProductTest?id='+id);
    // },
    // async ssDirect() {
    //   let j = await postSS('ProductService/GetProduct', {SellerId:'balaan0601', ProductId:4763857638});
    //   console.log(j.result);
    // },
    setStrNum() {
      getJson('/order/setStrNum');
    },
    async callFeedRenewCount() {
      // let j = await postFeed('/renew/count', {shop_id:[36]});
      let j = await postFeed('/renew/count', {goods_no:[1463243,2191684,2296681,2850986,2967433,3004384,3006176,3026084,3026138,3027435,2946991,3027450]});
      if (j) {
        this.$socket.emit('join', j.pid);
      }
    },
    async callFeedRenewPrice() {
      let j = await postFeed('/renew/price', {goods_no:[1463243,2191684,2296681,2850986,2967433,3004384,3006176,3026084,3026138,3027435,2946991,3027450]});
      if (j) {
        this.$socket.emit('join', j.pid);
      }
      // let j = await postFeed('/renew/price', {shop_id:[36]});
      alert(JSON.stringify(j));
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      let typeEl = event.target;
      while (!typeEl.dataset.type && typeEl !== document.body) {
        typeEl = typeEl.parentElement;
      }
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsxData(event.target, headers, rows);
    },
    async uploadXlsxData(target, headers, rows) {
      let j = await postJson('/dev/uploadXlsx', {coll:this.xlsxColl, id:this.xlsxId, rows});
      if (j) {
        alert(`${j.coll} collection 으로 업로드 되었습니다`);
      }
    },
    openToast() {
      window.t = this.$bvToast;
      this.$bvToast.toast(`여기를 누르시면 새로고침을 합니다.`, {
        id: 'test',
        variant: 'success',
        title: 'Test',
        autoHideDelay: 1000,
      });
      for (let i = 0; i < 3; i++) {
        setTimeout(() => {
          // this.$bvToast.show('test');
        }, i * 2000);
      }
    },
    // htb 데이터 테스트
    async getGMList() {
      const j = await this.$api.getJson('/dev/gmList');
      if (j) {
        this.htbItems = j.list;
      }
    }
  }
}
</script>
